.SignIn__modal_backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.9);
}

.SignIn__modal {
  width: 300px;
  position: absolute;
  top: 25%;
  left: 50%;
  margin-top: -150px;
  margin-left: -150px;
  z-index: 9999;
  background: white;
  padding: 1rem 2rem 2rem;
}

.modal_text {
  color: black;
}

.modal-link {
  color: blue;
  cursor: pointer;
  margin-right: 2rem;
}
