.Nav {
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5rem;
  display: flex;
  z-index: 1;
}

.Nav a {
  color: #ccc;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
}

.Nav a:hover {
  color: #ddd;
}

.Nav a.active {
  color: #fff;
}

.Nav > .wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
