.SignIn {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.SignIn__logo {
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 200px;
  height: auto;
}

.SignIn > form {
  padding: 2rem;
  border-radius: 10px;
  display: inline-block;
  background: ghostwhite;
  text-align: center;
}

.SignIn input {
  display: block;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-width: 1px;
  border-color: black;
  border-radius: 5px;
  width: 100%;
}

.SignIn input:last-of-type {
  margin-bottom: 1.5rem;
}

.SignIn button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
