.h-list {
  display: inline-flex;
  margin: 0;
  overflow: hidden;
  padding: 1rem 0;
  list-style: none;
  align-items: center;
}

.h-list > li {
  float: left;
  margin-right: 1.5rem;
}

.h-list > li:last-child {
  margin-right: 0;
}
