.LoadingSpinner {
  height: 50px;
  width: 50px;
  position: relative;
}

.LoadingSpinner > img {
  position: absolute;
  top: 8px;
  left: 9px;
}

.LoadingSpinner > svg > circle {
  fill: transparent;
  stroke-width: 2px;
  stroke: white;
  stroke-dasharray: 15;
  animation: 1s infinite linear LoadingSpinner;
}

@keyframes LoadingSpinner {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -30;
  }
}
