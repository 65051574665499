html {
  height: 100%;
  box-sizing: border-box;
  background-attachment: fixed;
  background-color: #3e8c5b;
  background-image: linear-gradient(#54b779, #3e8c5b);
  background-repeat: no-repeat;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  flex: 1;
  height: auto;

  font-family: system, -apple-system, BlinkMacSystemFont, sans-serif;
  color: white;

  -webkit-font-smoothing: antialiased;
}

.wrapper {
  margin: 0 auto;
  max-width: 100em;
  padding: 0 2rem;
}

h1,
.h1 {
  font-size: 3.3rem;
}
h2,
.h2 {
  font-size: 2.4rem;
}
h3,
.h3 {
  font-size: 1.8rem;
}

.anchor-plain {
  text-decoration: none;
  color: inherit;
}

pre,
.pre {
  text-align: left;
  background-color: #333;
  color: #e7e7e7;
  padding: 1.2rem;
  border-radius: 10px;
  font-size: 1.2rem;
  line-height: 1.2;
  letter-spacing: 0.3px;
  -webkit-font-smoothing: antialiased;
}

.gutter {
  padding-left: 30px;
  padding-right: 30px;
}

.island {
  padding-top: 30px;
  padding-bottom: 30px;
}

.islet {
  padding-top: 15px;
  padding-bottom: 15px;
}

.landmark {
  margin-bottom: 30px;
}

.landmarklet {
  margin-bottom: 15px;
}

.a-plain {
  text-decoration: inherit;
  color: inherit;
}

.Card {
  background-color: #fff;
  background-image: linear-gradient(#fff, #eee);
  border: 1px solid #ddd;
  color: #333;
  line-height: 1.5;
  display: flex;
  margin-bottom: -1px;
  margin-right: -1px;
}

.Card + .Card {
  border-top-color: #aaa;
}

.Card.disabled {
  background: #ddd;
  color: #888;
}

a:hover > .Card {
  background-image: none;
}

.flex1 {
  flex: 1;
}

dt {
  font-size: 1rem;
  color: #666;
}

dd {
  margin-left: 0;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.table {
  display: table;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.th {
  display: table-row;
  font-weight: bold;
}
.th > .td {
  border-bottom: 1px solid #ddd;
}

.tr {
  display: table-row;
  transition: background-color 0.05s;
}

.tr:hover {
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: none;
}

.td {
  display: table-cell;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 18px;
  padding-right: 18px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

table {
  border-collapse: collapse;
}

table.normal {
  width: 100%;
  background: white;
  color: black;
}

thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
table.normal thead {
  border-bottom-color: rgba(0, 0, 0, 0.4);
}

td,
th {
  text-align: left;
  padding: 0.2rem 2rem 0.2rem 0;
}

td:last-child,
th:last-child {
  padding-right: 0;
}

tr + tr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

table.normal tr + tr {
  border-top-color: rgba(0, 0, 0, 0.1);
}

table.normal td {
  padding: 0.5rem 0;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0;
  background: white;
  vertical-align: middle;
  border: 1px solid #656565;
  border-radius: 2px;
  box-shadow: inset 0px -2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.05s ease-in;
}

input[type='checkbox']:hover {
  box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

input[type='checkbox']:checked:after {
  content: '\2713';
  font-size: 1.3rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  line-height: 0.8;
  font-weight: bold;
  text-shadow: 0px 1px 2px #949494;
}

.metrics-box {
  margin: 0 10px;
  padding: 0 10px;
  width: 200px;
  height: 125px;
  background-color: #026c45;
}

.clicks-header {
  text-align: center;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}

.metrics-number {
  text-align: center;
  font-size: 42px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.metrics-header {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.leads-header {
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.leads-number {
  text-align: center;
  font-size: 25px;
  margin-bottom: 10px;
}

.no-results {
  margin-left: 10px;
  font-weight: 600;
  font-size: 22px;
}

.print-class {
  color: black;
}

.errorText {
  font-size: 30px;
}

.input-label {
  display: block;
  font-size: 27px;
}

.app-form {
  font: 95% Arial, Helvetica, sans-serif;
  max-width: 400px;
}

.app-form input[type="text"],
.app-form input[type="date"],
.app-form input[type="datetime"],
.app-form input[type="email"],
.app-form input[type="number"],
.app-form input[type="search"],
.app-form input[type="time"],
.app-form input[type="url"],
.app-form textarea,
.app-form select
{
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin-bottom: 4%;
  border: 1px solid #ccc;
  padding: 3%;
  color: #555;
}
.app-form input[type="text"]:focus,
.app-form input[type="date"]:focus,
.app-form input[type="datetime"]:focus,
.app-form input[type="email"]:focus,
.app-form input[type="number"]:focus,
.app-form input[type="search"]:focus,
.app-form input[type="time"]:focus,
.app-form input[type="url"]:focus,
.app-form textarea:focus,
.app-form select:focus
{
  box-shadow: 0 0 5px #43D1AF;
  padding: 3%;
  border: 1px solid #43D1AF;
}

.app-form input[type="submit"],
.app-form input[type="button"]{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 3%;
  background: #43D1AF;
  border-bottom: 2px solid #30C29E;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  color: #fff;
}
.app-form input[type="submit"]:hover,
.app-form input[type="button"]:hover{
  background: #2EBC99;
  cursor: pointer;
}

.app-form .form-label {
  font-size: 1.6em;
  margin: 5px 0px;
}

.app-form input[type="cancel"],
.app-form input[type="button"]{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 3%;
  margin-top: 5px;
  background: darkgrey;
  /*border-bottom: 2px solid #30C29E;*/
  border-top-style: none;
  text-align: center;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  color: #fff;
}

.app-form input[type="cancel"]:hover,
.app-form input[type="button"]:hover{
  background: lightgray;
  cursor: pointer;
}

#show-lead:hover{
  background: none;
}

.view-button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1500px) {
  .charts {
    display: block !important;
  }
}

@media print {
  /* All your print styles go here */
  body {
    background: #ffffff;
    padding: 20px;
  }

  @page {
    margin: 10px;
  }

  h3, h1 {
    color: black !important;
  }

  .recharts-surface {
    background: grey;
    width: 500px;
  }

  .charts {
    display: block !important;
  }

  .cart-image {
    max-height: 28%;
  }

  .Card__right, .Card__left {
    width: 45%;
    display: inline-block;
  }

  .Card.island.gutter {
    display: block;
    max-height: 100%;
  }
}

/* Janyer Option table th */
.sticky-th {
  width: 25%;
  top: 80px;
  position: sticky;
  background: white;
  padding: 0.5rem 0;
  z-index: 1;
}
