.options-chooser {
  color: black;
}

.options-chooser__tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin-top: 30px;
  margin-bottom: -2px;
}

.options-chooser__tabs__btn {
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  padding: 0.3rem 0.6rem;
  margin: 2px;
  margin-left: 0;
  font-size: 1rem;
}

.options-chooser__tabs__btn.active {
  background: white;
  color: black;
  cursor: initial;
}

.child-grid {
  padding: 30px 30px 0;
  background: white;
  display: flex;
  flex-wrap: wrap;
}

.child-grid__option {
  width: calc(100% / 4);
  margin-bottom: 30px;
  padding-right: 20px;
}

.child-grid__option button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.child-grid__option .selected {
  position: relative;
}

.child-grid__option .selected:after {
  content: "\2713";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: -70px;
  right: -15px;
  background: limegreen;
  border: 2px solid white;
  padding-left: 1px;
  font-size: 22px;
  color: white;
}

.price-input {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.price-input input {
  border: 1px solid #666;
  border-radius: 3px;
  padding: 2px 3px 3px 6px;
  margin-left: 5px;
  font-size: 0.8rem;
}

.options-chooser__save-btn {
  font-size: 1.5rem;
  padding: 0.25rem 1rem;
  float: right;
}
